import api from "../../service/api";

const GetListPatientDropdown = async (search) => {
  let path = "patient/patients/?list_type=drop_down&page=0&search=" + search;
  return api.get(path);
};

const GetListRevenueCode = async () => {
  let path = "super-admin/revenue/?&page=0";
  return api.get(path);
};

const ListInsuranceCompanies = async (practicePK) => {
  let path = null;
  path = "practice/insurance-company/?page=0&practice_pk=" + practicePK;
  return api.get(path);
};

const ListClaimDetails = async (
  patientPK,
  pageSize,
  page,
  claimPK,
  chageType,
  patientPaymentId,
  includeFamilyMember
) => {
  let path =
    "payments/claim-details/?patient_pk=" +
    patientPK +
    "&page_size=" +
    pageSize +
    "&page=" +
    page +
    "&charge_type=" +
    chageType +

    "&patient_payment_id=" + 
    patientPaymentId + 
    "&include_family_member=" +
    includeFamilyMember;
   
  if (claimPK) path += "&claim_pk=" + claimPK;
  return api.get(path);
};

const ListPayments = async (query) => {
  let path = "payments/view-payments/" + query;
  return api.get(path);
};

const CheckForPaymentExists = async (data) => {
  let path = "payments/check-payment-exists/";
  return api.post(path, data);
};

const SavePostPayment = async (data) => {
  let path = "payments/patient-payment/";
  return api.post(path, data);
};

const GetPaymentData = async (paymentPK) => {
  let path = "payments/patient-payment/" + paymentPK;
  return api.get(path);
};

const UpdatePostPayment = async (paymentPK, data) => {
  let path = "payments/patient-payment/" + paymentPK;
  return api.put(path, data);
};

const MakeAsReviewed = async (id, data) => {
  let path = "clearing-house/mark-as-reviewed/" + id + "/";
  return api.put(path, data);
};

const EraModalSave = async (id,data) => {
  let path = "clearing-house/save-era-procedure/" + id + "/"
  return api.put(path,data)
}

const SavePatientPaymentProcedures = async (data) => {
  let path = "payments/save-patient-payment-procedures/";
  return api.post(path, data);
};

const ListProviders = async (practicePK) => {
  let path = "user/provider/?page=0&list=true&practice_pk=" + practicePK;
  return api.get(path);
};

const ListCPTCodes = async (practicePK) => {
  let path =
    "practice/cpt-codes/?type=dropdown&page=0&practice_pk=" + practicePK;
    return api.get(path);
};

const ListICDCodes = async (practicePK) => {
  let path = "practice/icd10/?type=dropdown&page=0&practice_pk=" + practicePK;
  return api.get(path);
};

const ListClaimsDropDown = async (search, patientPK, practicePK) => {
  let path =
    "claim/claims/?drop_down=true&search=" +
    search +
    "&page=0&patient_pk=" +
    patientPK +
    "&practice_pk=" +
    practicePK;
    return api.get(path);
};

const ListClaimStatus = async (
  pageSize,
  page,
  practicePK,
  listType,
  patientPK,
  claimPK
) => {
  let path = null;
  path =
    "practice/claim-status/?page_size=" +
    pageSize +
    "&page=" +
    page +
    "&practice_pk=" +
    practicePK +
    "&list_type=" +
    listType;
  if (patientPK) path += "&patient_pk=" + patientPK;
  if (claimPK) path += "&opened_claim_pk=" + claimPK;
  return api.get(path);
};

const ListClaimStatusSubStatus = async (pageSize, page, practicePK) => {
  let path = null;
  path = 'practice/claim-status-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK ;
  return api.get(path);
}

const ListInsuranceNames = async (pageSize, page, practicePK, searchValue) => {
  let path =
    "practice/insurance-company/?page_size=" +
    pageSize +
    "&page=" +
    page +
    "&practice_pk=" +
    practicePK +
    "&search=" +
    searchValue+
    "&list=" +
    true;
    return api.get(path);
};

const GetListAdjustmentTypes = async (pageSize, page, listType) => {
  let path =
    "super-admin/payment-adjustment-type/?page_size=" +
    pageSize +
    "&page=" +
    page +
    "&list_type=" +
    listType;
    return api.get(path);
};

const GetListAdjustmentReasonCode = async (
  pageSize,
  page,
  searchValue,
  listType
) => {
  let path =
    "practice/reason-code/?page_size=" +
    pageSize +
    "&page=" +
    page +
    "&list_type=" +
    listType;
  if (searchValue) path += "&search=" + searchValue + "&name_search=True";
  return api.get(path);
};

const GetListRemittanceRemarkCode = async (pageSize, page, practicePK) => {
  let path =
    "practice/remark-code/?page_size=" +
    pageSize +
    "&page=" +
    page +
    "&practice_pk=" +
    practicePK;
    return api.get(path);
};
const SearchRemittanceRemarkCode = async (practicePK, query) => {
  let path =
    "practice/remark-code/?practice_pk=" +
    practicePK +
    "&page=0&search=" +
    query;
    return api.get(path);
};

const SaveInsurancePayment = async (data) => {
  let path = "payments/insurance-payment/";
  return api.post(path, data);
};

const ListInsurancePaymentProcedures = async (claimPK, pageSize, page,paymentDos) => {
  let path =
    "payments/insurance-payment-procedures/?claim_pk=" +
    claimPK +
    "&page_size=" +
    pageSize +
    "&page=" +
    page +
    "&payment_dos=" + 
    paymentDos;
    return api.get(path);
};

const ListInsurancePaymentClaims = async (query) => {
  let path = "payments/insurance-payment-claims/" + query;
  return api.get(path);
};

const GetInsurancePayment = async (paymentPK) => {
  let path = "payments/insurance-payment/" + paymentPK;
  return api.get(path);
};

const UpdateInsurancePayment = async (paymentPK, data) => {
  let path = "payments/insurance-payment/" + paymentPK;
  return api.put(path, data);
};

const SavedInsurancePaymentProcudures = async (paymentPK) => {
  let path =
    "payments/saved-insurance-payment-procedures/?payment_pk=" + paymentPK;
    return api.get(path);
};

const GetPatientAppliedPayments = async (paymentPK) => {
  let path =
    "payments/patient-applied-payment-procedures/?payment_pk=" + paymentPK;
    return api.get(path);
};

const GetReceipts = async (paymentPK, exportType) => {
  let path = "payments/view-receipts/?payment_pk=" + paymentPK;
  if (exportType) path += "&export_type=" + exportType;
  return api.get(path);
};

const GetInsurancePaymentsData = async (procedurePK) => {
  let path = "payments/insurance-payments-data/?procedure_pk=" + procedurePK;
  return api.get(path);
};

const GetEraListData = async (practicePK, qry) => {
  let path = "clearing-house/era-listing/"; //?practice_pk='+ practicePK;
  if (qry) path += qry;
  return api.get(path);
};

const GetERAPaymentExport = async (qry) => {
  let path = "clearing-house/era-payment-export/" + qry;
  return api.getXLS(path);
};

const exportERAPDF = async (qry) => {
  let path = "clearing-house/era-payment-export/" + qry;
  return api.get(path);
};

const GetClaimData = async (qry) => {
  let path = "clearing-house/era-payment-review/?" + qry;
  return api.get(path);
};

const UploadERA = async (data) => {
  let path = "clearing-house/era-upload/";
  return api.post(path, data);
};

const GetHeader = async (headerPK) => {
  let path = "clearing-house/era-payment-header/" + headerPK;
  return api.get(path);
};

const UpdateERA = async (headerPK,data) => {
  let path = "clearing-house/era-payment-header/" + headerPK;
  return api.put(path,data);
};

const GetClaimDataSummary = async (qry) => {
  let path = "clearing-house/era-payment-review-summary/?" + qry;
  return api.get(path);
};
const UpdateApplied = async (headerPK, appliedStatus) => {
  let path =
    "clearing-house/update-applied/?header_pk=" +
    headerPK +
    "&applied_status=" +
    appliedStatus;
    return api.get(path);
};
const EraAdjustmentType = async (headerPK) => {
  let path =
    "clearing-house/era-payment-adjustment-type/?header_pk=" + headerPK;
    return api.get(path);
};
const ReversePayment = async (data) => {
  let path = "clearing-house/reverse-payment/";
  return api.post(path, data);
};
const ExportERAList = async (data) => {
  let path = "clearing-house/era-list-export/";
  return api.post(path, data);
};
const ApplyAll = async (data) => {
  let path = "clearing-house/apply-all/";
  return api.post(path, data);
};

const ERAPayer = async () => {
  let path = "clearing-house/era-payer/";
  return api.get(path);
};
const getPatientPaymentPlans = async (patientId) => {
  let path = `payments/patient-payment-plan/?patient_id=${patientId}`;
  return api.get(path);
};

const GetResponsibilityType = async (patientPk, claimPk) => {
  let path = `claim/patient-responsibility-list/?patient_pk=${patientPk}&claim_pk=${claimPk ?? ""}`;
  return api.get(path);
};

const ClaimsDropdownForERAManualAdd = async (query, practice_pk) => {
  let path =
    "claim/claims/" +
    "?drop_down=true" +
    "&search=" + query +
    "&page=0" +
    "&patient_pk=" +
    "&practice_pk=" + practice_pk;  
    return api.get(path);
};

const GetClaimDataForEraManualAdd = async (query, practice_pk) => {
    let path =
    "claim/claims/" +
    "?practice_pk=" + practice_pk +
    "&claim_id=" + query +
    "&active=1" +
    "&list=true"
    return api.get(path);
};

const addClaimToEraManually = async (data) => {
  let path = "clearing-house/era-payment-claims/";
  return api.post(path, data);
};

const GetProcedureDataForManuallyAdded = async (query) => {
  let path = 'clearing-house/era-payment-claim-procedures/' + query;
  return api.get(path);
};

const SaveInsurancePaymentProceduresFromERAPosting = async (data) => {
  let path = "clearing-house/era-payment-apply/";
  return api.post(path, data);
};

const SaveInsurancePaymentProcedures = async (data) => {
  let path = "payments/save-insurance-payment-procedures/";
  const result = api.post(path, data);
  return result;
};

const GetPresignedEraURL = async (data) => {
  const result = await api.post('clearing-house/download-era-file/', data);
  return result;
}

const CheckForAlert = async (data) => {
  let path = 'patient/check-for-alert/';
  return api.post(path, data);
}

export default {
  GetListPatientDropdown,
  GetListRevenueCode,
  ListInsuranceCompanies,
  ListClaimDetails,
  ListPayments,
  CheckForPaymentExists,
  SavePostPayment,
  GetPaymentData,
  UpdatePostPayment,
  SavePatientPaymentProcedures,
  ListProviders,
  ListCPTCodes,
  ListICDCodes,
  ListClaimsDropDown,
  ListClaimStatus,
  ListInsuranceNames,
  GetListAdjustmentReasonCode,
  GetListRemittanceRemarkCode,
  SearchRemittanceRemarkCode,
  SaveInsurancePayment,
  ListInsurancePaymentProcedures,
  ListInsurancePaymentClaims,
  GetInsurancePayment,
  SaveInsurancePaymentProcedures,
  UpdateInsurancePayment,
  SavedInsurancePaymentProcudures,
  GetPatientAppliedPayments,
  GetReceipts,
  GetListAdjustmentTypes,
  GetInsurancePaymentsData,
  GetEraListData,
  GetERAPaymentExport,
  exportERAPDF,
  GetClaimData,
  UploadERA,
  GetHeader,
  UpdateERA,
  GetClaimDataSummary,
  UpdateApplied,
  ReversePayment,
  ExportERAList,
  ApplyAll,
  EraAdjustmentType,
  ERAPayer,
  getPatientPaymentPlans,
  GetResponsibilityType,
  ClaimsDropdownForERAManualAdd,
  GetClaimDataForEraManualAdd,
  addClaimToEraManually,
  GetProcedureDataForManuallyAdded,
  SaveInsurancePaymentProceduresFromERAPosting,
  GetPresignedEraURL,
  CheckForAlert,
  ListClaimStatusSubStatus,
  MakeAsReviewed,
  EraModalSave
};
